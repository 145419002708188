
import React, {useState} from "react"
import Layout from "../../components/layout"

import WebappForm from "../../components/webapps/form"

const negosyonowAPI = require("../../../lib/requestNegosyownow")
const webappsAPI = require("../../../lib/requestWebapps");


const ENTITYID="onsbranch"

const searchFields = [
	{"label":"Name", "dbfield": "onsorganization_name", "type": "text", "filtertype": "text"},
	{"label":"Short Name", "dbfield": "onsbranch_name", "type": "text", "filtertype": "text"},
	{"label":"Active", "dbfield": "onsbranch_active", "type": "checkbox", "filtertype": "checkbox"},
	{"label":"Image", "dbfield": "onsbranch_bannerimage", "type": "image", "filtertype": "image"}
];


const formFields = [
	{"label":"Name", "field": "onsorganization_name", "value": "", "input": "picker", "mode": "readonly"},
	{"label":"Short Name", "field": "onsbranch_name", "value": "", "input": "textbox", "mode": "required"},
	{"label":"Active", "field": "onsbranch_active", "value": "", "input": "checkbox", "mode": "readonly"},
	{"label":"Has Page", "field": "onsbranch_haspage", "value": "", "input": "hidden", "mode": "readonly"},
	{"label":"Webpage", "field": "onsbranch_url", "value": "", "input": "url", "mode": "readonly", "urlprefix":"https://negosyonow.com/organization/"},

	{"label":"Banner", "field": "onsbranch_bannerimage", "value": "", "input": "image", "mode": "normal", "imagemode":"fit", "imagewidth":1200, "imageheight":480, "imageformat":"jpg", "uploadtype": "https","filehost": "cdn.ngnw.ph", "filelocation":"images/organization/banner"},

	{"label":"Write-up", "field": "onsbranch_summary", "value": "", "input": "textarea", "mode": "normal"},

	//onsbranch_summary
];

const subformFields = [
	{
		"subformid": "onsbranchemail",
		"label": "email Notifications",
		"table": "onsbranchemail",
		"sort": "onsbranchemail_info",
		"mobilerowtitlefieldidx":[0],
		"minrow": 0,
		"maxrow": 10,
		"allowdel": true,
		"fieldlist": [
			{
				"label": "Email Address",
				"field": "onsbranchemail_info",
				"subtotalid": "",
				"value": "",
				"input": "email",
				"mode": "required"
			},
			{
				"label": "Active",
				"field": "onsbranchemail_active",
				"subtotalid": "",
				"value": "",
				"input": "checkbox",
				"mode": "normal"
			},
			{
				"label": "Custodian/Notes",
				"field": "onsbranchemail_notes",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "normal"
			}
		],
		"footerlist": [
		]
	},
	{
		"subformid": "onsbranchmobile",
		"label": "SMS Notifications",
		"table": "onsbranchmobile",
		"sort": "onsbranchmobile_info",
		"mobilerowtitlefieldidx":[0],
		"minrow": 0,
		"maxrow": 10,
		"allowdel": true,
		"fieldlist": [
			{
				"label": "11-digit # (09nnXXXXXXX)",
				"field": "onsbranchmobile_info",
				"subtotalid": "",
				"value": "",
				"input": "smsnumber",
				"mode": "required"
			},
			{
				"label": "Active",
				"field": "onsbranchmobile_active",
				"subtotalid": "",
				"value": "",
				"input": "checkbox",
				"mode": "normal"
			},
			{
				"label": "Custodian/Notes",
				"field": "onsbranchmobile_notes",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "normal",
				"info": "For selected notifications only"
			}
		],
		"footerlist": [
		]
	}
];




const SellerEditPage = ({location}) => {
	const [token, setToken] = useState("");
	const [pagetitle, setPagetitle] = useState("");

	function customSubmit(entity, params, payloadlist, subformpayloadlist, token, callback)
	{
		var pkid = 0;
		//var customparam = {};
		if (params.hasOwnProperty("documentid")) {
			pkid = params.documentid;
		}

		//callback({"status":"Error"}); return;

		webappsAPI.savePayload(entity, params, token, payloadlist, subformpayloadlist, function(response){
			if (response.status === "OK") {
				postSaveUpdates(entity, token, response.documentid, pkid === 0, function() {
					callback(response);
				});

			} else {
				callback(response);
			}
		});
	}


	function postSaveUpdates(entity, token, pkid, isnew, callback)
	{
		if (isnew) {
			negosyonowAPI.activateEntity(pkid,token, entity).then(nnactivateresponse => {
				// Activate Entity trigger build
				callback();
			});
		} else {
			negosyonowAPI.buildEntityId(pkid,token,"organization").then(nnresponse => {
				callback();
			});
		}
	}

	function customEditFieldInfo(inputlist, idx)
	{
		var tmpfield = {}
		// Add detaching/handling of parent product
		if (inputlist[idx].field === "onsbranch_url") {
			const haspage = parseInt(inputlist[idx-1].value);
			if (haspage === 1) {
				return inputlist[idx];
			}
			tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
			tmpfield.value = "Not yet available";
			tmpfield.input = "textbox";
			return tmpfield;
		}
		return inputlist[idx];
	}

	return <Layout location={location}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			newtokenHandler={(newtoken)=>{setToken(newtoken)}}
			privatePage={true} usegatedcontent={true}
		>
			<WebappForm
				pagetitle={pagetitle}
				allowadd={false}
				allowdelete={false}
				entity={ENTITYID}
				mobilerowtitlefield={["onsbranch_name"]}
				searchFields={searchFields}
				editFields={[formFields]}
				editSubFormFields={subformFields}

				customSubmit={customSubmit}
				customEditFieldInfo={customEditFieldInfo}

				token={token} />
		</Layout>

}


export default SellerEditPage;
